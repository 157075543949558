@font-face {
  font-family: 'asterism';
  src: url('asterism.woff2') format('woff2'),
       url('asterism.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futurist-Fixed-width';
  src: url('Futurist-Fixed-width.woff') format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
